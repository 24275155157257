.contactinfo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  overflow: hidden;
}
.socialmedia-container {
  position: relative;
}
.socialMedia-item a {
  text-decoration: none;
  color: black;
  font-family: "Benzin-ExtraBold";
  font-size: 44px;
  text-transform: uppercase;
}
.hover-text {
  display: block; /* Make both text spans block elements */
  position: absolute; /* Position them absolutely within the parent */
  top: 0; /* Stack them on top of each other */
  opacity: 0; /* Initially, they are fully visible */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.socialMedia-item:hover .social-media-text {
  opacity: 0; /* Fade out original text on hover */
}

.socialMedia-item:hover .hover-text {
  opacity: 1; /* Fade in hover text on hover */
}
.socialMedia-item {
  position: relative; /* Position relative to contain absolute children */
}
.smileyface {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 180px;
  bottom: 234px;
}
.smileyface img {
  width: 100%;
}
@media screen and (max-width: 500px) {
  .socialmedia-container {
    margin-top: 90px;
  }
  .smileyface {
    left: 80px;
    bottom: 216px;
  }
  .socialMedia-item:hover .hover-text {
    opacity: 0; /* Fade in hover text on hover */
  }
  .socialMedia-item:hover .social-media-text {
    opacity: 1; /* Fade out original text on hover */
  }
  .socialMedia-item {
    line-height: 26px;
  }
  .socialMedia-item a {
    font-size: 30px;
  }
  .contactinfo {
    height: 60vh;
  }
  .contact-container {
    height: unset !important;
  }
  .simlyface img {
    width: 450px;
    height: 600px;
  }
  .hover-text {
    display: none;
  }
}
