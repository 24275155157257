@import url("../../../variables.css");

.profile-title {
  font-size: 32px;
  width: 90%;
  margin: 50px auto;
  font-weight: bold;
}
.info-section,
.reset-password-section {
  border: 1px solid rgb(182, 182, 182);
  width: 90%;
  margin: 30px auto;
  padding: 20px 20px;
  border-radius: 8px;
}

.reset-password-section .submit-button button,
.info-section .submit-button button {
  background-color: var(--purple-primary-color);
  border-color: var(--purple-primary-color);
  background: var(--purple-primary-color);
}
.info-title,
.reset-title {
  font-size: 20px;
  margin-bottom: 30px;
}
.input-field {
  width: 45%;
}
.input-field input,
.input-field .p-password {
  width: 100%;
}
.submit-button {
  text-align: right;
}
.submit-button button {
  border-radius: 8px;
  width: 20%;
}
.oldpass input,
.oldpass .p-password {
  width: 100%;
}
.oldpass {
  margin-bottom: 20px;
}
.oldpass .p-icon-field,
#newPassword .p-icon-field,
#confirmnewPassword .p-icon-field {
  width: 100% !important;
}
