.footer p {
  text-align: center;
  padding: 30px;
}
.footer a {
  font-family: "Benzin-ExtraBold";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-decoration: none;
}
.mobile-version {
  display: none;
}
@media screen and (max-width: 500px) {
  .mobile-version {
    display: flex;
    flex-direction: column;
  }
  .mobile-version a {
    text-align: center;
    font-size: 20px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer p {
    display: none;
  }
}
