.infos-container {
  height: 100vh;
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projectnavbar {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.smileyfaceproject {
  width: 60px;
  margin: 30px;
}
.smileyfaceproject img {
  width: 100%;
  transform: rotate(-180deg) scale(1, -1);
}
.infos-container p {
  font-family: "Helvetica Neue LT Pro";
  font-size: 24px;
  text-align: justify;
  color: black;
}
.second-videoSlider-container,
.second-imageSlider-container {
  position: relative;
}
.imagesSlider-container {
  position: relative;
}

@media screen and (min-width: 2000px) {
  .infos-container {
    width: 60% !important;
  }
}
@media screen and (min-width: 1650px) and (max-width: 2000px) {
  .infos-container {
    width: 55% !important;
  }
}
@media screen and (max-width: 500px) {
  .projectnavbar {
    display: none;
  }
  .swipervideo-container,
  .infos-container {
    height: unset !important;
  }
  .infos-container {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .infos-container p {
    font-size: 16px;
    text-align: left;
  }
  .videoSlider-container video,
  .second-videoSlider-container video {
    height: unset !important;
    aspect-ratio: unset !important;
    width: 100% !important;
  }
}
