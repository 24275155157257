.projectnames-container {
  font-family: "Editor's Note Display";
  /* width: 85%; */
  width: 1033px;
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
  position: relative;
  line-height: 60px;
}
.homepage-container {
  /* height: 70vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-link {
  display: inline-block;
  position: relative;
  text-decoration: unset;
  color: black;
}
.overlay-image {
  opacity: 0;
  position: absolute; /* Position relative to the link */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Initial centering */
  pointer-events: none; /* Prevent mouse events on the overlay image */
  transition: opacity 0.3s ease;
  z-index: -2;
}
.project-link:hover .overlay-image {
  opacity: 1;
  /* width: 600px; */
}
.landscape {
  width: 800px;
}

.portrait {
  width: 550px;
}

.square {
  width: 650px;
}

.projectnames-container a:hover {
  color: white; /* Text color when hovered */
}
body.body-hover {
  background-color: #00fc49 !important; /* Green background on hover */
}
.overlay-image img {
  width: 100%;
  height: auto;
  z-index: -50;
  position: relative;
  will-change: transform;
}

@media screen and (max-width: 500px) {
  /* body {
    background-color: #00fc49 !important;
  } */
  .projectnames-container a:hover {
    color: black; /* Text color when hovered */
  }
  body.body-hover {
    background-color: #fff !important; /* Green background on hover */
  }
  .homepage-container {
    height: 60vh !important;
  }
  .separator {
    display: none;
  }
  .projectnames-container a {
    font-size: 24px;
    line-height: 40px;
  }
  .project-link:hover .overlay-image {
    display: none;
    /* width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    width: 100%; */
  }
  .overlay-image img {
    display: none;
    /* transform: unset !important; */
  }
  .projectnames-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    margin-bottom: 25px;
    flex-direction: column;
  }
}
