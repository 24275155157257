.loading-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.logoLoading {
  text-align: center;
}
.logoLoading h1 {
  font-family: "Benzin-ExtraBold";
  font-size: 40px;
}

.spinnerLoading {
  text-align: center;
}
