.swiper-container {
  width: 100%;
  height: 100%; /* Ensure swiper takes full height of its container */
}

.swiper-container .swiper-slide {
  width: auto !important; /* Ensure each slide takes the full width of the container */
  height: 100%; /* Maintain height consistency */
}

.swiper-container .swiper-slide img {
  height: 100%; /* Set image height to match the slide height */
  object-fit: cover; /* Cover the container while maintaining aspect ratio */
  width: auto; /* Adjust width to maintain aspect ratio */
}
.slider-container {
  position: relative;
}

.custom-prev-arrow,
.custom-next-arrow {
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* font-size: 2rem; */
  /* color: #ffffff; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* padding: 10px; */
  cursor: pointer;
  z-index: 10;
  /* border-radius: 50%; */
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}
.custom-prev-arrow img,
.custom-next-arrow img {
  width: 100%;
}
@media screen and (max-width: 500px) {
  .swiper-container .swiper-slide,
  .swiper-container .swiper-slide img {
    width: 100% !important;

    height: 50vh;
  }
  .custom-prev-arrow img,
  .custom-next-arrow img {
    width: 85%;
  }
  .swiper-container .swiper-slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-container .swiper-slide img {
    display: none;
  }

  .swiper-container {
    height: unset !important;
  }
  .imagesSlider-container .swiper-container {
    height: unset !important;
  }
  [class*="Jo-Malone-London-M&T-Gold-2"] {
    background-position: 60% !important;
  }
  [class*="Jo-Malone-London-Boxes"] {
    background-position: 46% !important;
  }
  [class*="Jo-Malone-London-M&T-Gold-1"] {
    background-position: 32% !important;
  }
}
