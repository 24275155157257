@import url("../../../variables.css");

.aboutuspageTitle {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.titleandsave .p-button.p-button-success {
  background-color: var(--purple-primary-color);
  background: var(--purple-primary-color);
  border: none;
  border-radius: 10px;
}
.titleandsave .p-button.p-button-success:hover {
  background-color: var(--light-color-hover);
}
.titleandsave .p-button:focus {
  box-shadow: none !important;
}
.titleandsave {
  margin-bottom: 20px;
}
.mobilecontent {
  margin-top: 20px;
}
