.about-content {
  font-family: "Helvetica Neue LT Pro";
  width: 70%;
  margin: 0 auto;
  line-height: 30px;
  font-size: 22px;
}
.aboutus-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00fc49;
}
.about-content strong {
  font-family: "Helvetica Neue LT Pro Md";
}
@media screen and (max-width: 500px) {
  .about-content {
    font-size: 20px;
    text-align: left;
    width: 85%;
    margin-bottom: 30px;
    line-height: 25px;
  }
  .aboutus-container {
    height: 60vh !important;
  }
}
