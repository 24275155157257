@font-face {
  font-family: "Benzin-ExtraBold";
  src: url("../public/fonts/Benzin-ExtraBold/Fonts/Supremat\ -\ Benzin\ ExtraBold.ttf")
    format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Helvetica Neue LT Pro";
  src: url("../public/fonts/HelveticaNeueLTPro-Lt.otf") format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Helvetica Neue LT Pro Bd";
  src: url("../public/fonts/HelveticaNeueLTStd-Bd.otf") format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Helvetica Neue LT Pro Md";
  src: url("../public/fonts/HelveticaNeueLTPro-Md.otf") format("truetype"); /* Adjust the path to your font file */
}
@font-face {
  font-family: "Editor's Note Display";
  src: url("../public/fonts/Editors-Note-Display-Thin/Fonts/EditorsNoteDisplay-Thin.otf")
    format("truetype"); /* Adjust the path to your font file */
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
#root {
  overflow: hidden;
}
