:root {
  --purple-primary-color: #2a4c4e;
  --light-color-hover: #40908e;
  --light-color-background: #b3ebea;
  --grey-dark-color: #2f2f32;
  --grey-light-color-borders: #d1d5db;
}
.p-checkbox .p-checkbox-input {
  border-color: #40908e;
}
.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #40908e;
  background-color: #40908e;
}

.p-button[aria-label="Cancel"] {
  background-color: #ef4444 !important; /* Change to your desired color */
}
