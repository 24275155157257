.swipervideo-container {
  width: 100%;
  height: 100vh;
}

.swipervideo-container video {
  image-rendering: auto;
  object-fit: fill; /* Ensure the video scales without cropping */
  object-position: center center;
  pointer-events: auto;
}
.slider-container {
  position: relative;
}

.custom-prev-arrow,
.custom-next-arrow {
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* font-size: 2rem; */
  /* color: #ffffff; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* padding: 10px; */
  cursor: pointer;
  z-index: 10;
  /* border-radius: 50%; */
}
.custom-down-arrow-video {
  position: absolute;
  bottom: 30px;
  left: 50%; /* Move to the center horizontally */
  transform: translateX(-50%); /* Adjust to center exactly */
  z-index: 10;
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}
.custom-down-arrow-video {
  cursor: pointer;
}

/* .custom-prev-arrow:hover,
.custom-next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
} */
@media screen and (max-width: 500px) {
  .custom-down-arrow-video {
    display: none;
  }
}
