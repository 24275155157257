.subnav {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.subnav a {
  width: 320px;
  text-decoration: none;
  font-family: "Benzin-ExtraBold";
  color: #000000;
  font-size: 20px;
  font-weight: 800;
  opacity: 1;
  padding-top: 2px;
}
.subnav a:last-child {
  text-align: right;
}
.mainlogo {
  width: unset !important;
  font-size: 30px !important;
  padding:unset !important ;
}
.navbar-mobile-version {
  display: none;
}
@media screen and (max-width: 1150px) {
  .mainlogo {
    text-align: center;
    line-height: 27px;
  }
  .subnav {
    align-items: center;
  }
}
@media screen and (max-width:815px) {
  .letsconnectlink{
    text-align: center !important;
    line-height: 18px;
    padding-bottom: 7px;
   
  }
  .subnav a{
    text-align: center;
    padding-top: unset !important;
  }
}
@media screen and (max-width: 500px) {
  .menu-icon {
    width: 25%; /* Set the size of the button */
    /* height: 70px; */
  }
  .right-menu-bar-button {
    display: flex;
    justify-content: end;
  }
  .navbar-mobile-version {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .subnav {
    display: none;
  }
  .navbar-container {
    width: 90%;
    margin: 25px auto;
    margin-top: 40px;
  }
  .logomobile {
    color: #000000;
    font-size: 20px;
    line-height: 18px;
    text-decoration: none;
    font-family: "Benzin-ExtraBold";
    padding-top: 15px;
  }
  .right-menu-bar {
    text-align: right;
  }
  .right-menu-bar .p-button {
    border: unset;
    background-color: unset;
    color: #000000;
  }
  .right-menu-bar .p-button .pi {
    font-size: 2rem;
  }
  .menubar-mobile .p-sidebar-header {
    display: none;
  }
  .menubar-mobile .p-sidebar-header,
  .menubar-mobile .p-sidebar-content {
    background-color: #00fc49;
  }
  .right-side-bar {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .p-sidebar-right .p-sidebar {
    width: 100%;
  }
  .right-side-bar a {
    text-decoration: none;
    color: #000000;
    font-family: "Benzin-ExtraBold";
    text-transform: uppercase;
    font-size: 28px;
    text-align: center;
  }
  .right-side-bar {
    margin-top: 100px;
  }
  .sidebar-header {
    background-color: #00fc49; /* Example background color */
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 40px auto;
  }
  .sidebar-header a {
    display: flex;
    align-items: center;
  }
  .closeicon i {
    font-size: 40px;
    color: #000000;
  }
  a:hover {
    color: #000000;
  }
  .mobilenav-corporate a {
    text-align: center;
    font-size: 32px;
    line-height: 28px;
  }
  .mobilenav-corporate {
    justify-content: center;
    align-items: center;
  }
}
